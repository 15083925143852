import React, { useContext, useState } from 'react';
import { DistribuitionCartelaContext } from '../../context/distribuitionCartela';
import { toast } from 'react-toastify';
import {
  convertDate,
  convertDateBrForUs,
  convertVisualDateForTimestamp,
} from '../../services/helpers';

// STYLES
import {
  ContainerModal,
  ContentWithButtonEdit,
  DivModal,
  HeaderText,
  ModalHeader,
  SendCartelaButton,
} from './style/ModalStyle';
import {
  Input,
  Label,
  MessageAlert,
} from '../../pages/DistribuitionCartelas/styles/DistribuitionCartelasStyle';
import { FaSquarePen } from 'react-icons/fa6';
import { CircularProgress } from '@mui/material';

// API
import { editSeries } from '../../services/APIs/editSeries';

export const ModalEditSerie = ({ isOpen, close, dataSerieSelected }) => {
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const uId = JSON.parse(localStorage.getItem('uid'));
  const [loading, setLoading] = useState(false);
  const [editedInformations, setEditedInformations] = useState({});
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState({
    button1: true,
    button2: true,
    button3: true,
    button4: true,
    button5: true,
    button6: true,
    button7: true,
    button8: true,
    button9: true,
  });

  const cancelDateForLiquidationRenderizedConditionInput = editedInformations.hasOwnProperty(
    'cancelDateForLiquidation'
  )
    ? editedInformations.cancelDateForLiquidation
    : dataSerieSelected.cancelDateForLiquidation !== 'NaN/NaN/NaN'
    ? dataSerieSelected.cancelDateForLiquidation &&
      convertDateBrForUs(dataSerieSelected.cancelDateForLiquidation)
    : '';

  const modalIsClosed = () => {
    setIsDisabled({
      button1: true,
      button2: true,
      button3: true,
      button4: true,
      button5: true,
      button6: true,
      button7: true,
      button8: true,
      button9: true,
    });
    setEditedInformations({});
    setMessage('');
    close();
  };

  const SaveEditSerie = () => {
    const hasSerieStartDate = editedInformations.hasOwnProperty('serieStartDate');
    const hasSerieEndDate = editedInformations.hasOwnProperty('serieEndDate');
    const hasDistrStartDate = editedInformations.hasOwnProperty('distrStartDate');
    const hasDistrEndDate = editedInformations.hasOwnProperty('distrEndDate');
    const hasStopSellingDate = editedInformations.hasOwnProperty('stopSellingDate');
    const hasCancelDateForLiquidation = editedInformations.hasOwnProperty(
      'cancelDateForLiquidation'
    );
    const totalCartelas = editedInformations.hasOwnProperty('totalCardFisical')
      ? +editedInformations.totalCardFisical
      : dataSerieSelected.totalCardFisical + editedInformations.hasOwnProperty('totalCardDigital')
      ? +editedInformations.totalCardDigital
      : dataSerieSelected.totalCardDigital;

    const payload = {
      ...editedInformations,
      serieStartDate:
        editedInformations.hasOwnProperty('serieStartDate') &&
        convertDate(editedInformations.serieStartDate, 'start'),
      serieEndDate:
        editedInformations.hasOwnProperty('serieEndDate') &&
        convertDate(editedInformations.serieEndDate, 'final'),
      distrStartDate:
        editedInformations.hasOwnProperty('distrStartDate') &&
        convertDate(editedInformations.distrStartDate, 'start'),
      distrEndDate:
        editedInformations.hasOwnProperty('distrEndDate') &&
        convertDate(editedInformations.distrEndDate, 'final'),
      stopSellingDate:
        editedInformations.hasOwnProperty('stopSellingDate') &&
        convertDate(editedInformations.stopSellingDate, 'final'),
      cancelDateForLiquidation:
        editedInformations.hasOwnProperty('cancelDateForLiquidation') &&
        convertDate(editedInformations.cancelDateForLiquidation, 'start'),
      totalCardFisical: +editedInformations.totalCardFisical,
      totalCardDigital: +editedInformations.totalCardDigital,
    };

    // REMOVE OS ITENS FALSO OU UNDEFINED
    for (let i in payload) {
      if (i !== 'totalCardDigital' && !payload[i]) {
        delete payload[i];
      }

      if (i === 'totalCardDigital' && isNaN(payload[i])) {
        delete payload[i];
      }
    }

    // NÃO PERMITE + DE 50000 CARTELAS EM UMA SÉRIE
    if (totalCartelas > 50000) {
      return setMessage('Uma série não pode possuir mais de 50 mil cartelas');
    }

    // VALIDAÇÕES DE DATAS
    //VERIFICACAO DA ATIVACAO
    if (hasSerieStartDate && hasSerieEndDate) {
      if (
        convertDate(editedInformations.serieStartDate, 'start') >=
        convertDate(editedInformations.serieEndDate, 'final')
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior ou igual a data de ativação.'
        );
      }
    } else if (hasSerieStartDate && !hasSerieEndDate) {
      if (
        convertDate(editedInformations.serieStartDate, 'start') >=
        convertVisualDateForTimestamp(dataSerieSelected.serieEndDate)
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior ou igual a data de ativação.'
        );
      }
    } else if (!hasSerieStartDate && hasSerieEndDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.serieStartDate) >=
        convertDate(editedInformations.serieEndDate, 'final')
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior ou igual a data de ativação.'
        );
      }
    }

    if (hasSerieStartDate && hasStopSellingDate) {
      if (
        convertDate(editedInformations.serieStartDate, 'start') >=
        convertDate(editedInformations.stopSellingDate, 'final')
      ) {
        return setMessage(
          'Data limite de vendas não pode ser anterior ou igual a data de ativação da série.'
        );
      }
    } else if (hasSerieStartDate && !hasStopSellingDate) {
      if (
        convertDate(editedInformations.serieStartDate, 'start') >=
        convertVisualDateForTimestamp(dataSerieSelected.stopSellingDate)
      ) {
        return setMessage(
          'Data limite de vendas não pode ser anterior ou igual a data de ativação da série.'
        );
      }
    } else if (!hasSerieStartDate && hasStopSellingDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.serieStartDate) >=
        convertDate(editedInformations.stopSellingDate, 'final')
      ) {
        return setMessage(
          'Data limite de vendas não pode ser anterior ou igual a data de ativação da série.'
        );
      }
    }
    //VERIFICACAO DA DESATIVACAO
    if (hasSerieEndDate && hasDistrStartDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <=
        convertDate(editedInformations.distrStartDate, 'start')
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de desativação da série.'
        );
      }
    } else if (hasSerieEndDate && !hasDistrStartDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <=
        convertVisualDateForTimestamp(dataSerieSelected.distrStartDate)
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de desativação da série.'
        );
      }
    } else if (!hasSerieEndDate && hasDistrStartDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.serieEndDate) <=
        convertDate(editedInformations.distrStartDate, 'start')
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de desativação da série.'
        );
      }
    }

    if (hasSerieEndDate && hasDistrEndDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <
        convertDate(editedInformations.distrEndDate, 'final')
      ) {
        return setMessage(
          'Data do fim da distribuição não pode ser posterior a data desativação da série.'
        );
      }
    } else if (hasSerieEndDate && !hasDistrEndDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <
        convertVisualDateForTimestamp(dataSerieSelected.distrEndDate)
      ) {
        return setMessage(
          'Data do fim da distribuição não pode ser posterior a data desativação da série.'
        );
      }
    } else if (!hasSerieEndDate && hasDistrEndDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.serieEndDate) <
        convertDate(editedInformations.distrEndDate, 'final')
      ) {
        return setMessage(
          'Data do fim da distribuição não pode ser posterior a data desativação da série.'
        );
      }
    }

    if (hasSerieEndDate && hasStopSellingDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <
        convertDate(editedInformations.stopSellingDate, 'final')
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior a data limite de vendas.'
        );
      }
    } else if (hasSerieEndDate && !hasStopSellingDate) {
      if (
        convertDate(editedInformations.serieEndDate, 'final') <
        convertVisualDateForTimestamp(dataSerieSelected.stopSellingDate)
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior a date limite de vendas.'
        );
      }
    } else if (!hasSerieEndDate && hasStopSellingDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.serieEndDate) <
        convertDate(editedInformations.stopSellingDate, 'final')
      ) {
        return setMessage(
          'Data de desativação da série não pode ser anterior a date limite de vendas.'
        );
      }
    }
    // VERIFICACAO DA DISTRIBUICAO DA SERIE
    if (hasDistrStartDate && hasDistrEndDate) {
      if (
        convertDate(editedInformations.distrStartDate, 'start') >
        convertDate(editedInformations.distrEndDate, 'final')
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de fim da distribuição.'
        );
      }
    } else if (hasDistrStartDate && !hasDistrEndDate) {
      if (
        convertDate(editedInformations.distrStartDate, 'start') >
        convertVisualDateForTimestamp(dataSerieSelected.distrEndDate)
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de fim da distribuição.'
        );
      }
    } else if (!hasDistrStartDate && hasDistrEndDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.distrStartDate) >
        convertDate(editedInformations.distrEndDate, 'final')
      ) {
        return setMessage(
          'Data do inicio da distribuição não pode ser posterior ou igual a data de fim da distribuição.'
        );
      }
    }

    if (hasCancelDateForLiquidation && hasSerieEndDate) {
      if (
        convertDate(editedInformations.cancelDateForLiquidation, 'start') <
        convertDate(editedInformations.serieEndDate, 'final')
      ) {
        return setMessage(
          'Data limite para não pagamento de títulos cancelados não pode ser anterior a data de desativação da série.'
        );
      }
    } else if (hasCancelDateForLiquidation && !hasSerieEndDate) {
      if (
        convertDate(editedInformations.cancelDateForLiquidation, 'start') <
        convertVisualDateForTimestamp(dataSerieSelected.serieEndDate)
      ) {
        return setMessage(
          'Data limite para não pagamento de títulos cancelados não pode ser anterior a data de desativação da série.'
        );
      }
    } else if (!hasCancelDateForLiquidation && hasSerieEndDate) {
      if (
        convertVisualDateForTimestamp(dataSerieSelected.cancelDateForLiquidation) <
        convertDate(editedInformations.serieEndDate, 'final')
      ) {
        return setMessage(
          'Data limite para não pagamento de títulos cancelados não pode ser anterior a data de desativação da série.'
        );
      }
    }

    setLoading(true);

    editSeries(uId, dataSerieSelected.id, payload)
      .then(() => {
        toast.success('Serie editada com sucesso.');
        localStorage.setItem('updateList', true);
        modalIsClosed();
      })
      .catch((err) => {
        toast.error('Algo não funcionou corretamente, tente novamente mais tarde.');
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setMessage('');
      });
  };

  const handleChange = (e, type) => {
    const value = e.target.value;

    switch (type) {
      case 'serieStartDate':
        setEditedInformations({
          ...editedInformations,
          serieStartDate: value,
        });
        break;
      case 'serieEndDate':
        setEditedInformations({
          ...editedInformations,
          serieEndDate: value,
        });
        break;
      case 'distrStartDate':
        setEditedInformations({
          ...editedInformations,
          distrStartDate: value,
        });
        break;
      case 'distrEndDate':
        setEditedInformations({
          ...editedInformations,
          distrEndDate: value,
        });
        break;
      case 'stopSellingDate':
        setEditedInformations({
          ...editedInformations,
          stopSellingDate: value,
        });
        break;
      case 'cancelDateForLiquidation':
        setEditedInformations({
          ...editedInformations,
          cancelDateForLiquidation: value,
        });
        break;
      case 'totalCardFisical':
        setEditedInformations({
          ...editedInformations,
          totalCardFisical: value,
        });
        break;
      case 'totalCardDigital':
        setEditedInformations({
          ...editedInformations,
          totalCardDigital: value,
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className={`modal ${isOpen ? 'modal is-active' : 'modal'}`}>
      <div className="modal-background" onClick={() => modalIsClosed()}></div>
      <DivModal style={{ width: '30em' }} radius className="modal-card">
        <ModalHeader className="modal-card-head">
          <HeaderText className="modal-card-title">
            {dataSerieSelected.status === 'registered' ? 'Editar série' : 'Visuzalizar série'}
          </HeaderText>
        </ModalHeader>
        <ContainerModal className="modal-card-body">
          <h4>Série: {serie === '' ? serieUrl : serie}</h4>
          <Label>Série*</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button1}
              maxLength={2}
              placeholder="AA"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              value={dataSerieSelected.serie}
            />
          </ContentWithButtonEdit>
          <Label>Data de ativação da série*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button2}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'serieStartDate')}
              value={
                editedInformations.hasOwnProperty('serieStartDate')
                  ? editedInformations.serieStartDate
                  : Object.keys(dataSerieSelected).length > 0
                  ? convertDateBrForUs(dataSerieSelected.serieStartDate)
                  : ''
              }
            />

            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button2: !isDisabled.button2,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Data de vencimento da série*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button3}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'serieEndDate')}
              value={
                editedInformations.hasOwnProperty('serieEndDate')
                  ? editedInformations.serieEndDate
                  : Object.keys(dataSerieSelected).length > 0
                  ? convertDateBrForUs(dataSerieSelected.serieEndDate)
                  : ''
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button3: !isDisabled.button3,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Data do início da distribuição*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button4}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'distrStartDate')}
              value={
                editedInformations.hasOwnProperty('distrStartDate')
                  ? editedInformations.distrStartDate
                  : Object.keys(dataSerieSelected).length > 0
                  ? convertDateBrForUs(dataSerieSelected.distrStartDate)
                  : ''
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button4: !isDisabled.button4,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Data do fim da distribuição*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button5}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'distrEndDate')}
              value={
                editedInformations.hasOwnProperty('distrEndDate')
                  ? editedInformations.distrEndDate
                  : Object.keys(dataSerieSelected).length > 0
                  ? convertDateBrForUs(dataSerieSelected.distrEndDate)
                  : ''
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button5: !isDisabled.button5,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Data limite para vendas*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button6}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'stopSellingDate')}
              value={
                editedInformations.hasOwnProperty('stopSellingDate')
                  ? editedInformations.stopSellingDate
                  : Object.keys(dataSerieSelected).length > 0
                  ? convertDateBrForUs(dataSerieSelected.stopSellingDate)
                  : ''
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button6: !isDisabled.button6,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Data limite para não pagamento de títulos cancelados *:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button7}
              type="date"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'cancelDateForLiquidation')}
              value={cancelDateForLiquidationRenderizedConditionInput}
            />

            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button7: !isDisabled.button7,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Número de títulos físicos*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button8}
              placeholder="35000"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'totalCardFisical')}
              value={
                editedInformations.hasOwnProperty('totalCardFisical')
                  ? editedInformations.totalCardFisical
                  : dataSerieSelected.totalCardFisical
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button8: !isDisabled.button8,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          <Label>Número de titulos digitais*:</Label>
          <ContentWithButtonEdit>
            <Input
              disabled={isDisabled.button9}
              placeholder="5000"
              className={
                window.screen.width > 450 ? 'input is-fullwidth' : 'input is-fullwidth is-small'
              }
              required
              onChange={(e) => handleChange(e, 'totalCardDigital')}
              value={
                editedInformations.hasOwnProperty('totalCardDigital')
                  ? editedInformations.totalCardDigital
                  : dataSerieSelected.totalCardDigital
              }
            />
            {dataSerieSelected.status === 'registered' && (
              <FaSquarePen
                size={30}
                style={{ cursor: 'pointer', color: '#2D70B9' }}
                onClick={() =>
                  setIsDisabled({
                    ...isDisabled,
                    button9: !isDisabled.button9,
                  })
                }
              />
            )}
          </ContentWithButtonEdit>
          {message !== '' && <MessageAlert style={{ color: 'red' }}>{message}</MessageAlert>}
          {dataSerieSelected.status === 'registered' ? (
            <SendCartelaButton
              style={{ marginTop: '1rem' }}
              onClick={SaveEditSerie}
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size="26px" /> : 'Salvar alterações'}
            </SendCartelaButton>
          ) : (
            <MessageAlert>
              <strong>SOMENTE VISUALIZAÇÃO</strong>
            </MessageAlert>
          )}
        </ContainerModal>
      </DivModal>
    </div>
  );
};
