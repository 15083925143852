import React from 'react';
import MUIDataTable from 'mui-datatables';
import { NumericFormat } from 'react-number-format';

// STYLES
import { StyledDiv } from './style/MUIDatatable';
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material';
import { BottomMargin } from '../../pages/SellerPage/style/SellerPageStyle';
import { BsCircleFill } from 'react-icons/bs';

export default function SaleOffTable({ infosForTable /* loadingTable, messageApi, relatory, */ }) {
  const displayData = infosForTable.relatory; // Passa as informações do body da tabela

  const columns = [
    {
      name: 'liquidationDate',
      label: 'Data da liquidação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'quantity',
      label: 'Quantidade de Vendas (Leads)',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'totalValue',
      label: 'Valor (R$)',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              decimalPrecision={2}
              decimalSeparator=","
              displayType={'text'}
              thousandSeparator="."
              value={value}
            />
          );
        },
      },
    },
    {
      name: 'initialDate',
      label: 'Data do inicio',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'endDate',
      label: 'Data do fim',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'sendedLiquidationDate',
      label: 'Data de liquidação',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'operatorName',
      label: 'Operador',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (value) => {
          switch (value) {
            case 'Liquidação pendente':
              return (
                <span>
                  <BsCircleFill style={{ color: '#ffa500' }} />
                  &nbsp; Liquidação pendente
                </span>
              );
            case 'Liquidando':
              return (
                <span>
                  <BsCircleFill style={{ color: '#0088C4' }} />
                  &nbsp; Liquidando
                </span>
              );
            case 'Liquidado':
              return (
                <span>
                  <BsCircleFill style={{ color: '#2DB94D' }} />
                  &nbsp; Liquidado
                </span>
              );
            default:
              break;
          }
        },
      },
    },
  ];

  const tableOptions = {
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false,
    download: false,
    filter: false,
    responsive: 'simple',
    filterType: 'textField',
    textLabels: {
      body: {
        noMatch: '',
        toolTip: `${'table.toolTip'}`,
        columnHeaderTooltip: (column) => `Ordenando por ${column.label}`,
      },
      pagination: {
        next: `próxima página`,
        previous: `página anterior`,
        rowsPerPage: `linhas por página`,
        displayRows: `de`,
      },
      toolbar: {
        search: `Pesquisar`,
        filterTable: `Filtrar tabela`,
      },
      filter: {
        title: `Filtros`,
        reset: `Resetar`,
      },
      viewColumns: {
        title: `${'table.viewColumnsTitle'}`,
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              fontSize: '85%',
              textAlign: 'center',
              cursor: 'default',
            },
          },
        },
      },
    });

  return (
    <BottomMargin style={{ width: '70%' }}>
      <StyledDiv
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ThemeProvider theme={getMuiTheme()}>
          {infosForTable.loadingTable ? (
            <CircularProgress size={100} thickness={4} style={{ marginTop: '3rem' }} />
          ) : (
            <MUIDataTable
              className={'table is-mobile'}
              data={displayData}
              columns={columns}
              options={tableOptions}
            />
          )}
        </ThemeProvider>
      </StyledDiv>
    </BottomMargin>
  );
}
