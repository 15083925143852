import { useContext, useEffect, useState } from 'react';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { toast } from 'react-toastify';

// STYLES
import { Button, Grid, MenuItem, Select } from '@mui/material';
import { Content, InsideContent } from '../../../styled/generic';

// COMPONENTS
import CardErrosTable from '../../../components/Tables/CardErrosTable';
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import ModalConfirmWithErro from '../../../components/Modals/ModalConfirmWithErro';

// APIS
import { getLeadsWithoutTimeoutError } from '../../../services/APIs/getLeadsWithoutTimeoutError';
import { getLeadsWithTimeoutError } from '../../../services/APIs/getLeadsWithTimeoutError';
import { updateLeads } from '../../../services/APIs/updateLeads';

export const CardsWithErros = () => {
  const uid = JSON.parse(localStorage.getItem('uid'));
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);

  const [erroChecked, setErroChecked] = useState([]);
  const [erroList, setErroList] = useState([]);
  const [erroType, setErroType] = useState('timeout');
  const [loading, setLoading] = useState(true);
  const [loadingProccessing, setLoadingProccessing] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    switch (erroType) {
      case 'timeout':
        getLeadsWithTimeoutError(serie ? serie : serieUrl).then((res) => {
          res.length === 0 && toast.warning('Nenhuma emissão com erro.');
          setErroList(res);
          setLoading(false);
        });
        break;
      case 'others':
        getLeadsWithoutTimeoutError(serie ? serie : serieUrl).then((res) => {
          res.length === 0 && toast.warning('Nenhuma emissão com erro.');
          setErroList(res);
          setLoading(false);
        });
        break;
      default:
        break;
    }
  }, [erroType, serie, serieUrl]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const reproccesedCards = async () => {
    try {
      setLoadingProccessing(!loadingProccessing);
      const response = await updateLeads(uid, erroChecked);

      response && toast.success('Solicitação de reprocessamento efetuado.');
      setOpenModal(false);
    } catch (err) {
      console.log(err);
      toast.error('Algo não funcionou corretamente, tente novamente mais tarde.');
    } finally {
      setLoadingProccessing(false);
    }
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <Grid container justifyContent="center">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={12} md={6} sx={{ margin: '0 2.5rem 1rem 0' }}>
                <Select
                  onChange={(e) => setErroType(e.target.value)}
                  size="small"
                  sx={{ minWidth: '10rem' }}
                  value={erroType}
                >
                  <MenuItem value="timeout">Timeout</MenuItem>
                  <MenuItem value="others">Outros erros</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={1} sx={{ marginLeft: '10rem' }}>
                <Button
                  disabled={erroChecked.length === 0}
                  onClick={handleModal}
                  type="button"
                  variant="contained"
                >
                  Reprocessar
                </Button>
              </Grid>
              <CardErrosTable
                erroChecked={erroChecked}
                infosForTable={erroList}
                loading={loading}
                setErroChecked={setErroChecked}
              />
              <ModalConfirmWithErro
                action={reproccesedCards} // API OU FUNÇÃO DO BOTÃO DE CONFIRMAR
                actionCancelatio // DEFINE O QUE FAZER QUANDO CANCELAR
                canceled={true} // TRUE OU FALSE SE DESEJAR QUE EXIBA UM BOTÃO DE CANCELAR
                close={handleModal} // FECHA MODAL
                labelButton="Prosseguir" // LABEL DO BOTÃO DE CONFIRMAR
                labelCancelation="Cancelar" // LABEL DO BOTÃO DE CANCELAR
                open={openModal} // ESTADO DO MODAL
                title="Reprocessar emissões" // TITULO DO MODAL
                loading={loadingProccessing}
              />
            </Grid>
          </Grid>
        </InsideContent>
      </Content>
    </>
  );
};
