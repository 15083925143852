import { useContext, useEffect, useState } from 'react';
import { DistribuitionCartelaContext } from '../../../context/distribuitionCartela';
import { convertDate, convertToFullDateNoHours } from '../../../services/helpers';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';

// STYLE
import { Content, InsideContent } from '../../../styled/generic';
import { FcCancel } from 'react-icons/fc';
import { MdOutlinePayments } from 'react-icons/md';
import { Input, Label } from '../styles/DistribuitionCartelasStyle';
import { BsCircleFill } from 'react-icons/bs';
import { CircularProgress, Tooltip } from '@mui/material';
import {
  ActiveResumePaper,
  ButtonSendDateSaleOff,
  ContainerButtons,
  ContainerSaleOff,
  ContentActionButtonSaleOff,
  ContentButtons,
} from './style';

// COMPONENTS
import HeaderComp from '../../../components/Header/HeaderComp';
import SideBarDesktop from '../../../components/Sidebar/SideBarComp';
import SaleOffTable from '../../../components/Tables/SaleOffTable';
import { ModalUploadConfirmPaymentDistribuition } from '../../../components/Modals/ModalUploadConfirmPaymentDistribuition';

// APIS
import { getAllLiquidationFromSerie } from '../../../services/APIs/getAllLiquidationFromSerie';
import { createLiquidationRelatory } from '../../../services/APIs/createLiquidationRelatory';
import { changeLiquidationStatus } from '../../../services/APIs/changeLiquidationStatus';

export function SaleOff() {
  const uId = JSON.parse(localStorage.getItem('uid'));
  const { serie, serieUrl } = useContext(DistribuitionCartelaContext);
  const [messageApi, setMessageApi] = useState('');
  const [loadingButtonAction, setLoadingButtonAction] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingGenerationResume, setLoadingGenerationResume] = useState(false);
  const [operationId, setOperationId] = useState('');
  const [dates, setDates] = useState({
    start: '',
    end: '',
    liquidation: '',
  });
  const [relatory, setRelatory] = useState([]);
  const [relatoryActive, setRelatoryActive] = useState({});
  const [reloadPage, setReloadPage] = useState(false);
  const [isOpenModalConfirmPayment, setIsOpenModalConfirmPayment] = useState(false);
  const infosForTable = {
    loadingTable,
    messageApi,
    relatory,
  };

  useEffect(() => {
    setLoadingTable(true);
    getAllLiquidationFromSerie(uId, serie ? serieUrl : serieUrl)
      .then((res) => {
        // PREPARA AS INFORMAÇÔES PARA SER EXIBIDO NA TABELA
        const preparedRelatory = res.map((relatory) => {
          return {
            ...relatory,
            liquidationDate: convertToFullDateNoHours(relatory.liquidationDate),
            initialDate: convertToFullDateNoHours(relatory.initialDate),
            endDate: convertToFullDateNoHours(relatory.endDate),
            sendedLiquidationDate: convertToFullDateNoHours(relatory.sendedLiquidationDate),
            status:
              relatory.status === 'liquidationPending'
                ? 'Liquidação pendente'
                : relatory.status === 'liquidate'
                ? 'Liquidando'
                : relatory.status === 'liquidated'
                ? 'Liquidado'
                : null,
          };
        });

        // NÃO EXIBE A SERIE ATIVA NA TABELA DE HISTÓRICO
        const filteredRelatory = preparedRelatory.filter(
          (item) => item.status !== 'Liquidação pendente'
        );

        setRelatory(filteredRelatory);
        setRelatoryActive(
          preparedRelatory.find((relatory) => relatory.status === 'Liquidação pendente')
        );
      })
      .catch((err) => {
        setMessageApi(err);
      })
      .finally(() => {
        setLoadingTable(false);
      });
  }, [uId, serie, serieUrl, reloadPage]);

  // SETA O ID PARA OPERAÇÃO
  useEffect(() => {
    setOperationId(relatoryActive?.id);
  }, [relatoryActive?.id]);

  const handleStatusModalConfirmPayment = () => {
    setIsOpenModalConfirmPayment(!isOpenModalConfirmPayment);
    setOperationId(relatoryActive?.id);
  };

  // GERA O RELATÓRIO
  const createLiquidation = async () => {
    const payload = {
      serie: serie ? serie : serieUrl,
      initialDate: dates.start === '' ? '' : convertDate(dates.start, 'start'),
      endDate: dates.end === '' ? '' : convertDate(dates.end, 'final'),
      sendedLiquidationDate:
        dates.liquidation === '' ? '' : convertDate(dates.liquidation, 'start'),
    };

    if (payload.initialDate >= payload.endDate) {
      return toast.warning('A data inicial não pode ser anterior a data final.');
    }

    setLoadingGenerationResume(true);
    createLiquidationRelatory(uId, payload)
      .then((res) => {
        toast.success(res);
        setDates({ start: '', end: '', liquidation: '' });
        setReloadPage(!reloadPage);
      })
      .catch((err) => toast.error(err))
      .finally(() => setLoadingGenerationResume(false));
  };

  // CANCELA E LIQUIDA RELATÓRIO
  const liquidationOrCancelRelatory = (type) => {
    let status;
    switch (type) {
      case 'liquidation':
        status = true;
        break;
      case 'cancel':
        status = false;
        setLoadingButtonAction(true);
        break;
      default:
        break;
    }

    changeLiquidationStatus(uId, operationId, status)
      .then(() => {
        toast.success(
          status ? 'Liquidação efeteuada com sucesso.' : 'Relatório cancelado com sucesso.'
        );
        setReloadPage(!reloadPage);
      })
      .catch((err) => toast.error(err))
      .finally(() => {
        setLoadingButtonAction(false);
      });
  };

  return (
    <>
      <SideBarDesktop />
      <Content id="content">
        <HeaderComp />
        <InsideContent id="inside-content">
          <ContainerSaleOff>
            <ActiveResumePaper elevation={3}>
              {loadingTable ? (
                <CircularProgress size={35} thickness={6} />
              ) : !relatoryActive || Object.keys(relatoryActive).length === 0 ? (
                'Gere um novo relatório para iniciar a liquidação.'
              ) : (
                <>
                  <strong>RELATÓRIO ATIVO</strong>
                  <span>
                    <BsCircleFill style={{ color: '#37F713' }} size="14px" />
                    <p>{relatoryActive.quantity} unid.</p>
                    <p>
                      <NumericFormat
                        decimalPrecision={2}
                        decimalSeparator=","
                        displayType={'text'}
                        thousandSeparator="."
                        prefix={'R$ '}
                        value={relatoryActive.totalValue}
                      />
                    </p>
                    <p>{relatoryActive.initialDate}</p>
                    <p>{relatoryActive.endDate}</p>
                    <p>{relatoryActive.sendedLiquidationDate}</p>
                    <p>{relatoryActive.operatorName}</p>
                    <p>{relatoryActive.status}</p>
                    <ContentActionButtonSaleOff>
                      <Tooltip title="Cancelar" placement="top">
                        <button
                          onClick={() => liquidationOrCancelRelatory('cancel')}
                          disabled={loadingButtonAction}
                        >
                          {loadingButtonAction ? (
                            <CircularProgress
                              size={20}
                              style={{ color: '#0066cc' }}
                              thickness={6}
                            />
                          ) : (
                            <>
                              <FcCancel size={22} /> Cancelar
                            </>
                          )}
                        </button>
                      </Tooltip>
                      <Tooltip title="Liquidar" placement="top">
                        <button onClick={handleStatusModalConfirmPayment}>
                          <MdOutlinePayments size={20} style={{ color: '#07bc0c' }} /> Liquidar
                        </button>
                      </Tooltip>
                    </ContentActionButtonSaleOff>
                  </span>
                </>
              )}
            </ActiveResumePaper>

            <ContainerButtons>
              <ContentButtons>
                <Label>Data inicial:</Label>
                <Input
                  type="date"
                  required
                  onChange={(e) => setDates({ ...dates, start: e.target.value })}
                  value={dates.start}
                />
              </ContentButtons>
              <ContentButtons>
                <Label>Data final:</Label>
                <Input
                  type="date"
                  required
                  onChange={(e) => setDates({ ...dates, end: e.target.value })}
                  value={dates.end}
                />
              </ContentButtons>
              <ContentButtons>
                <Label>Data de liquidação:</Label>
                <Input
                  type="date"
                  required
                  onChange={(e) => setDates({ ...dates, liquidation: e.target.value })}
                  value={dates.liquidation}
                />
              </ContentButtons>
              <ContentButtons>
                <ButtonSendDateSaleOff
                  disabled={
                    dates.start === '' ||
                    dates.end === '' ||
                    dates.liquidation === '' ||
                    loadingGenerationResume
                  }
                  onClick={createLiquidation}
                  variant="contained"
                >
                  {loadingGenerationResume ? (
                    <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
                  ) : (
                    'Gerar novo relatório'
                  )}
                </ButtonSendDateSaleOff>
              </ContentButtons>
            </ContainerButtons>
          </ContainerSaleOff>
          <strong style={{ margin: '0.4rem 0 1rem 0' }}>HISTÓRICO DE RELATÓRIOS</strong>
          <SaleOffTable infosForTable={infosForTable} />
          <ModalUploadConfirmPaymentDistribuition
            close={handleStatusModalConfirmPayment}
            open={isOpenModalConfirmPayment}
            action={liquidationOrCancelRelatory}
            fileName={relatoryActive?.id}
          />
        </InsideContent>
      </Content>
    </>
  );
}
